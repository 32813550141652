<template>
  <section class="container-scroller">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <id class="card-body">
            <h3 class="card-title text-white rounded p-3 mb-4 text-center" style="background-color: #0b5d3f; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              Effectuer un transfert de stock
            </h3>

            <form @submit.prevent="addSupply" class="forms-sample row"> 
              <div class="col-md-12" style="margin-bottom: 15px;">
                <div class=" row col-md-12">
                  <div class="form-group form-controls col-md-3">
                    <label for="store">Magasin source<span style="color: red">*</span></label>
                    <select 
                      :class="{ 
                        'form-control': true,
                        'is-invalid': formErrors.supply.store_id 
                      }" 
                      v-model="supply.store_id"
                      @change="getStocks(supply.store_id)"
                      class="form-control" id="store">
                      <option value="null" selected>Selectionner un magasin </option>
                      <option v-for="store in stores" :key="store.uuid" :value="store.uuid">
                        {{ store.name }}
                      </option>
                    </select>

                    <div class="invalid-feedback" v-if="formErrors.supply.store_id">
                      {{ formErrors.supply.store_id }}
                    </div>
                  </div>

                  <div class="form-group form-controls col-md-3">
                    <label for="stock">Stock source<span style="color: red">*</span></label>
                    <select 
                      :class="{ 
                        'form-control': true,
                        'is-invalid': formErrors.supply.stock_id
                      }" 
                      v-model="supply.stock_id"
                      @change="setStock(supply.stock_id)"
                      class="form-control" id="stock" required>
                      <option value="null" selected>Selectionner un stock</option>
                      <option v-for="stock in stocks" :key="stock.uuid" :value="stock.uuid">
                        {{ stock.name }}
                      </option>
                    </select>

                    <div class="invalid-feedback" v-if="formErrors.supply.stock_id">
                      {{ formErrors.supply.stock_id }}
                    </div>
                  </div>

                  <div class="form-group form-controls col-md-3">
                    <label for="store">Magasin Destination<span style="color: red">*</span></label>
                    <select 
                      :class="{ 
                        'form-control': true,
                        'is-invalid': formErrors.supply.store_id 
                      }" 
                      v-model="supply.store_id"
                      @change="getStocks(supply.store_id)"
                      class="form-control" id="store">
                      <option value="null" selected>Selectionner un magasin </option>
                      <option v-for="store in stores" :key="store.uuid" :value="store.uuid">
                        {{ store.name }}
                      </option>
                    </select>

                    <div class="invalid-feedback" v-if="formErrors.supply.store_id">
                      {{ formErrors.supply.store_id }}
                    </div>
                  </div>

                  <div class="form-group form-controls col-md-3">
                    <label for="stock">Stock Destination<span style="color: red">*</span></label>
                    <select 
                      :class="{ 
                        'form-control': true,
                        'is-invalid': formErrors.supply.stock_id
                      }" 
                      v-model="supply.stock_id"
                      @change="setStock(supply.stock_id)"
                      class="form-control" id="stock" required>
                      <option value="null" selected>Selectionner un stock</option>
                      <option v-for="stock in stocks" :key="stock.uuid" :value="stock.uuid">
                        {{ stock.name }}
                      </option>
                    </select>

                    <div class="invalid-feedback" v-if="formErrors.supply.stock_id">
                      {{ formErrors.supply.stock_id }}
                    </div>
                  </div>
           
                </div>
                
                <div class="d-flex flex-column mt-3 mb-1 pt-3 pl-4 " style="background-color: rgb(248, 248, 248); border-radius: 5px;">
                  <div class="d-flex flex-column mb-2" id="suppllyProductList">
                    <div class="form-group row customize-row">
                      <div class="col-md-5"><label class="form-label">Produit<span class="text-danger">*</span></label></div>
                      <div class="col-md-3"><label class="form-label">Qu. Disponible<span class="text-danger">*</span></label></div>
                      <div class="col-md-3"><label class="form-label">Qu. à transférer<span class="text-danger">*</span></label></div>
                      <div class="col-md-1"><button @click="addProductToSupply" id="addProductToSupply" class="btn btn-success btn-sm add" style="font-size: 10px;" type="button" name="add"><span class="fa fa-plus"></span></button></div>
                    </div>
                    
                    <div class="form-group row customize-row">
                      <div class="col-md-5">
                        <select 
                          :class="{ 
                            'form-control': true,
                            'is-invalid': formErrors.supplyProduct.product_specifications_id
                          }" 
                          v-model="supplyProducts[0].supplyProduct.product_id"
                          class="form-control" id="productSpecification" required>
                          <option value="null" selected>Selectionner un Produit </option>
                          <option v-for="productSpecification in productSpecifications" :key="productSpecification.uuid" :value="productSpecification.uuid">
                            {{ productSpecification.name }}
                          </option>
                        </select> 
                      </div>

                      <div class="col-md-3">
                        <input type="text" v-model="supplyProducts[0].supplyProduct.purchase_price" @change="updateTotals" min="0" max="1000000000000" class="form-control" id="purchase_price-1" disabled>
                      </div>    
                      <div class="col-md-3">
                        <input type="number" v-model="supplyProducts[0].supplyProduct.units_per_box" min="1" max="1000000" class="form-control unityPerBox" required/>
                      </div>   
                      <div class="col-md-1">
                        <button class="btn btn-danger btn-sm remove remove" id="remove-0" @click="removeProductFromSupply($event, 0)" min="formattedExpirationDate" style="font-size: 10px;" type="button" name="remove">
                          <span class="fa fa-minus"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between col-12 flex-row">
                <button @click="cancel" class="btn btn-danger btn-medpay-gray">
                  Annuler
                </button>

                <button type="submit" class="btn btn-success btn-medpay-green">
                  Enregistrer
                </button>
              </div>
            </form>
          </id>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { storeService } from "@/_services";
import { productSpecificationService } from "@/_services";
import { supplyService } from "@/_services";
import { supplyProductService } from "@/_services";
import { useToast, POSITION } from "vue-toastification";

export default {
  name: "create-supply",
  data() {
    return {
      stores: {},
      stocks: {},
      typeProducts: {},
      categories: {},
      productSpecifications: {},

      supply: {
        stock_id: null,
        store_id: null,
        lot_number: "",
        total: null,
      },

      supplyProducts: [
        {
          supplyProduct: {
            product_id: null,
            units_per_box: 1,
            expire_date: this.formattedExpirationDate,
            quantity: 1,
            purchase_price: 0,
            supply_id: null,
          },   
        }
      ],
      total: null,
      formErrors: { 
        supply: {
        stock_id: '',
        store_id: '',
        lot_number: "",
        },
        supplyProduct: {
          type_id: "",
        },
        productSpecification: {
          type_id: "",
        },
      },
    };
  },
  computed: {
    formattedExpirationDate() {
      // Calculate tomorrow's date
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      // Format the date to "yyyy-MM-dd"
      return tomorrow.toISOString().substr(0, 10);
    },
  },
  mounted() {
    //initialize the select2 feature on my select
    $('#store').select2();
    $('#stock').select2();
    $('#typeProduct').select2();
    $('#category').select2();
    $('#productSpecification').select2();

    // Get a reference to the input element
    const expirationDateInput = this.$refs.expirationDateInput;

    //set the first product expiration date to tomorrow
    this.supplyProducts[0].supplyProduct.expire_date = this.formattedExpirationDate;

    // Set the minimum date to tomorrow
    expirationDateInput.min = this.formattedExpirationDate;

    // Add an event listener to prevent choosing dates less than tomorrow
    expirationDateInput.addEventListener('input', () => {
      if (expirationDateInput.value < this.formattedExpirationDate) {
        expirationDateInput.value = this.formattedExpirationDate;
      }
    });

    $('#store').on('change', (e) => {
      this.getStocks(e.target.value);
    });

    $('#typeProduct').on('change', (e) => {
      this.getCategories(e.target.value);
    });

    //get all stores
    storeService
    .getAllStores()
    .then((res) => {
      console.log(res);
      this.stores = res.data.data
      try {
        //get all stocks related to the first element in this.stores
        let firstStoreUuid = this.stores[0].uuid;
        this.supply.store_id = firstStoreUuid;
        storeService
        .getStocks(firstStoreUuid)
        .then((response) => {
          this.stocks = response.data.data;
          this.supply.stock_id = this.stocks[0].uuid;
        })
        .catch((error) => {
          console.log(error);
        });
      } 
      catch (error) {
      }
    })
    .catch((err) => {
      console.log(err);
    });

    //get all productSpecifications
    productSpecificationService
    .getAllProductSpecifications()
    .then((res) => {
      this.productSpecifications = res.data.data;
      this.supplyProducts[0].supplyProduct.product_id = this.productSpecifications[0].uuid;
    })
    .catch((err) => {
      console.log(err);
    });

  },
  methods: {
    addProductToSupply(){
      if (this.supplyProducts.length >= 50) {
        this.$toast.error('Vous ne pouvez approvisionner plus de cinquante produits en même temps!', {
          duration: 5000, // Toast message duration in milliseconds
          position: POSITION.TOP_RIGHT, // Toast message position on the screen
        });
        return; // Exit the function to prevent further execution
      }

      this.supplyProducts.push({
        supplyProduct: {
          product_id: null,
          units_per_box: null,
          expire_date: null,
          quantity: null,
          purchase_price: null,
          supply_id: null,
        },
      });

      let supplyProductCount = this.supplyProducts.length;

      var html = '';
      html += '<div class="form-group row customize-row">';
      html += '<div class="col-md-3">';
      html += '<select class="form-control" id="product-'+supplyProductCount+'" required>';
      html += '<option value="null" selected>Selectionner un Produit </option>';
      for (let i=0 ;i < this.productSpecifications.length; i++){
        html += '<option key="'+this.productSpecifications[i].uuid+'" value="'+this.productSpecifications[i].uuid+'">'+ this.productSpecifications[i].name +'</option>';
      }
      html += '</select>';
      html += '</div>';
    
      html += '<div class="col-md-2">'
      html += '<input type="number" min="0" max="1000000000000" value="0" class="form-control" id="purchasePrice-'+supplyProductCount+'" required>';
      html += '</div>' 

      html += '<div class="col-md-2">'
      html += '<input type="number" min="1" max="1000000" value="1" class="form-control" id="units_per_box-'+supplyProductCount+'" required/>'
      html += '</div>'

      html += '<div class="col-md-2">'
      html += '<input type="number" min="1" max="1000000" value="1" class="form-control" id="quantity-'+supplyProductCount+'" required>'
      html += '</div>'

      html += '<div class="col-md-2">'
      html += '<input type="date" class="form-control" value="'+this.formattedExpirationDate+'" min="'+this.formattedExpirationDate+'" id="expire_date-'+supplyProductCount+'" required>';
      html += '</div>'

      html += '<div class="col-md-1">';
      html += '<button class="btn btn-danger btn-sm remove remove" style="font-size: 10px;" type="button" name="remove" id="remove-'+supplyProductCount+'">';
      html += '<span class="fa fa-minus"></span>';
      html += '</button>';
      html += '</div>';

      html += '</div>';
    
      $('#suppllyProductList').append(html);
      
      $('#product-' + supplyProductCount).change((event) => this.updateProductIdElement(supplyProductCount - 1, event.target.value));
      $('#purchasePrice-' + supplyProductCount).change((event) => this.updatePurchasePriceElement(supplyProductCount - 1, event.target.value));
      $('#units_per_box-' + supplyProductCount).change((event) => this.updateUnitPerBoxElement(supplyProductCount - 1, event.target.value));
      $('#quantity-' + supplyProductCount).change((event) => this.updateQuantityElement(supplyProductCount - 1, event.target.value));
      $('#expire_date-' + supplyProductCount).change((event) => this.updateExpireDateElement(supplyProductCount - 1, event.target.value));
      $('#remove-' + supplyProductCount).on('click', ((event) => this.removeProductFromSupply(event, supplyProductCount - 1)));
      
      $(document).ready(function () {
        $('#product-' + supplyProductCount).select2();
      });  
    },

    removeProductFromSupply(event, index) {  
      $(event.target).closest('.form-group').remove();
      this.supplyProducts.splice(index, 1);
      console.log( this.supplyProducts);
    },
    removeProductFromSupplyTab(index) {  
      this.supplyProducts.splice(index, 1);
    },
    updateProductIdElement(index, value)
    {
      //Before updating the new product added to my supply in my tab I want to prevent the same product from existing twice 
      const productExists = this.supplyProducts.some(product => product.supplyProduct.product_id === value);
      if (productExists) {
        // If the product_id already exists, remove the newly added tab and line
        this.supplyProducts.pop(); // Remove the last item from the array
        $('#product-' + (index + 1)).closest('.form-group').remove();
        //raise an error
        this.$toast.error('Vous ne pouvez sélectionner deux fois le même produit pour un approvisionnement', {
          duration: 5000, // Toast message duration in milliseconds
          position: POSITION.TOP_RIGHT, // Toast message position on the screen
        });
        return; 
      }
      this.supplyProducts[index].supplyProduct.product_id = value;
    },
    updatePurchasePriceElement(index, value)
    {
      this.supplyProducts[index].supplyProduct.purchase_price = value;
      this.updateTotals();
    },
    updateUnitPerBoxElement(index, value)
    {
      this.supplyProducts[index].supplyProduct.units_per_box = value;
    },
    updateQuantityElement(index, value)
    {
      this.supplyProducts[index].supplyProduct.quantity = value;
      this.updateTotals();
    },
    updateExpireDateElement(index, value)
    {
      this.supplyProducts[index].supplyProduct.expire_date = value;
    },

    updateTotals() {
      this.total = 0;
      this.supplyProducts.forEach((product) => {
        // Assurez-vous que quantity et purchase_price sont des nombres valides
        if (!isNaN(product.supplyProduct.quantity) && !isNaN(product.supplyProduct.purchase_price)) {
          this.total += product.supplyProduct.quantity * product.supplyProduct.purchase_price;
        } else {
          // Gérez le cas où l'une des valeurs n'est pas un nombre valide
          this.total += 0; // Ou une autre valeur par défaut
        }
      });
    },

    //get the stocks related to a specific store
    getStocks(uuid)
    {
      storeService
      .getStocks(uuid)
      .then((response) => {
        this.stocks = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    setStock(uuid)
    {
      supply.store_id = uuid;
    },
    cancel() {
      this.$toast.error("Enregistrement annulé!", {
        position: "top-right",
        timeout: 3000,
        style: {
          background: "#ff0000", // Couleur de fond rouge pour l'erreur
          color: "#fff", // Couleur du texte
          fontWeight: "bold",
        },
      });
      this.$router.push("/stock/list");
    },
    addSupply() {
      //Prevent the user to save a supply with no line of products      
      if (this.supplyProducts.length == 0) {
        this.$toast.error('Vous devez ajouter au moins un produit à votre approvisionnement!', {
          duration: 5000, // Toast message duration in milliseconds
          position: POSITION.TOP_RIGHT, // Toast message position on the screen
        });
        return; // Exit the function to prevent further execution
      }
      
      this.supply.total = this.total;
      supplyService
      .addSupply(this.supply)
      .then((response) => {
        //Put the value of the supply uuid in all elements of my array
        this.supplyProducts.forEach((product) => {
          product.supplyProduct.supply_id = response.data.data.uuid;
        });
        console.log(response);

        const promises = this.supplyProducts.map((product) => {
          return supplyProductService.addSupplyProduct(product.supplyProduct);
        });

        Promise.all(promises)
        .then((responses) => {
          console.log("All supply products added successfully:", responses);
          // After all products are registered, navigate to "/transfert/list"
          this.$router.push("/transfert/list");
        })
        .catch((error) => {
          console.log("Error while adding supply products:", error);
        });
    

        //this.$router.push("/transfert/list");
        this.$toast.success("Enregistrement effectué avec succès !", {
          position: POSITION.TOP_RIGHT,
          timeout: 3000,
          bodyStyle: {
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontWeight: "bold",
          },
        });
      })
      .catch((error) => {
        console.log(error);

        // try {
        //   this.formErrors.stock.name = error.response.data.errors.name[0];
        // } 
        // catch (error) {
        // }
      });
    },
  },
};
</script>

<style>
.form-group {
  margin-bottom: 0.5rem;
}
.select2-container--default .select2-selection--single {
  height: 45px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0b5d3f;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 10px;
  padding-top: 8px;
}
select {
  cursor: pointer;
  height: 43px !important;
}

input[type="radio"].demo6 {
  display: none;
}

input[type="radio"].demo6 + label {
  position: relative;
  padding-left: 1.3rem;
}

input[type="radio"].demo6 + label::before,
input[type="radio"].demo6 + label::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-radius: 1rem;
}

input[type="radio"].demo6 + label::before {
  bottom: 0;
  left: 0;
  border: 1px solid #ccc;
  background-color: #eee;
  width: 1rem;
  height: 1rem;
}

input[type="radio"].demo6 + label::after {
  bottom: 3px;
  left: 3px;
  width: calc(1rem - 6px);
  height: calc(1rem - 6px);
}

input[type="radio"].demo6:checked + label::after {
  background-color: #45c28e;
}

input[type="checkbox"].demo2 {
  display: none;
}

input[type="checkbox"].demo2 + label::before {
  content: "";
  border: 1px solid #45c28e;
  padding: 0 0.6rem;
  margin-right: 0.3rem;
}

input[type="checkbox"].demo2:checked + label::before {
  background-color: #45c28e;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.product-list-container {
  max-height: 250px; 
  margin-bottom: 30px;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ccc;
}
.modal .form-row
{
  margin-bottom: -5px;
}

.b-table-sticky-header, .table-responsive, [class*="table-responsive-"] {
  margin-bottom: 1rem;
  box-shadow: 0 2px 1px #e3e3e3;
}

.modal-content {
  background-color: white;
  border: none;
}

.customize-row
{
  margin-left: 0px;
  margin-right: 0px;
}
.customize-row .col-md-2,
.customize-row .col-md-1,
.customize-row .col-md-3
{
  padding-right: 4px;
  padding-left: 4px;
}

.customize-row button
{
  font-size: 10px;
}

.card .card-title {
  text-transform: none !important;
}

</style>
